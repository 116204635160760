import React, { useState } from "react";
import ProgressBar2 from '../../components/progress2';
import api from '../../services/api';
import './style.css';
import { useAuth } from "../../context/auth";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import {deepPurple,teal} from '@material-ui/core/colors';
import { TextField } from "@material-ui/core";

import {useGoogleOneTapLogin} from "react-google-one-tap-login"

function Login(props) {

  
  useGoogleOneTapLogin({
    onSuccess:(response)=> {
        postLogin(1,response.email)
      
      
    },
    onError:(error)=> console.log(error),
    googleAccountConfigs:{
      client_id: process.env.REACT_APP_GOOGLE_CLIENT,
      cancel_on_tap_outside:false
    }
  })


  //const [setLoggedIn] = useState(false);
  const [isError, setIsError] = useState(false);
  const [email, setEmail] = useState("");
  const [forget, setForget] = useState(true);
  const [senha, setSenha] = useState("");
  const { setAuthTokens } = useAuth();
  const [op,setOp] = useState(1);
  const [perc,setPerc] = useState(0);

  
  function enterClick(e) {

    if(e.keyCode === 13){
      postLogin()
    }
    }

    function showForget(){
      setForget(false)
    }

    function forgetEmail(){
      api.post("get/forget.php?email="+email+'&r='+Math.random(10000)).then(result => {
        alert('Confira seu e-mail para ver a senha.')
        setForget(true)
      })
    }

  function postLogin(g,qemail) {
    let doEmail = qemail
    if(!doEmail){
      doEmail=email
    }
   
    api.post("get/auth.php?email="+doEmail+"&senha="+senha+"&google="+g+'&r='+Math.random(10000)).then(result => {
      
    
      if (result.status === 200) {
        
       
        setOp(1)
        setPerc(100)
        localStorage.setItem("load", 30);
        setAuthTokens(result.data.split('|')[1]);
        delete localStorage['tk_nav'];
        localStorage['empresa_id'] = (result.data.split('|')[0]);
        localStorage['permission'] = (result.data.split('|')[2]);
        localStorage['usuario_id'] = (result.data.split('|')[3]);
        localStorage['foto'] = result.data.split('|')[5];
        localStorage['nome'] = result.data.split('|')[6];
        localStorage['page'] = (result.data.split('|')[4]);
        localStorage['twillio_id'] = (result.data.split('|')[7]);
        localStorage['twillio_auth'] = (result.data.split('|')[8]);
        
        
        if(result.data.split('|')[0]){
          
          // MOBILE
          if(( window.innerWidth <= 800 )){
            window.location.href = '/reembolso';
          
          }else{
       
            window.location.href = '/budgets';

        }

        }else{
          setOp(0)
          setPerc(100)
          setIsError(true);
        }
        
      } else {
        setIsError(true);
      }
    }).catch(e => {
      
      setIsError(false);
    });

    
  }

  

  const theme = createTheme (
    {
      palette: {
        type: "dark",
        primary: deepPurple,
        secondary: teal,
      
    }
  })

  return (
    <div>
      
      <ThemeProvider theme={theme}>
      <ProgressBar2 op={op}  perc={perc} />
      
    <div className="conteudo conteudo-login">
       
        <div className="box-login vertical-center">
          <div className="mb5">
            <TextField
            autoFocus={true} 
            variant="outlined" 
            fullWidth={true} 
            type="email"
            login={true}
            value={email}
            autoComplete='off'
            
            onChange={e => {
    
              setEmail(e.target.value)
             
            }}


            placeholder="email@email.com"
            label="E-mail"
            InputProps={{style: { height:0,padding: '25px 0px'}, }}
          />
          </div>
          <div className="mt10">
            {forget ? 
          <TextField
            variant="outlined" 
            fullWidth={true} 
            type="Password"
            value={senha}
            autoComplete='off'
            
            onChange={e => {
    
              setSenha(e.target.value)
             
            }}
            login={true}
            label="Senha"
            
            onKeyDown={(e)=>enterClick(e)}
            InputProps={{style: { height:0,padding: '25px 0px'}, }}
          />
          :''}
          </div>
          
          {forget ? 
            <div className="bt large bgcolor_1 mt10 mb20" onClick={postLogin}>Sign In</div>
          :
            <div className="bt large bgcolor_1 mt10 mb20" onClick={forgetEmail}>Send pass to e-mail</div>
          }


          { isError &&<div>E-mail ou senha incorretas</div> }

          {forget ? <div onClick={showForget} className="underline">Esqueci a senha</div> : ''}
        </div>
      </div>
      </ThemeProvider>

      
      
    </div>
        
  );
}

export default Login;